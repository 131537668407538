import React, { useState, useEffect } from 'react';
import { withAuth } from '@okta/okta-react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import queryString from 'query-string';
import appConstants from "../../Assets/appConstants";
import { useLocation } from 'react-router-dom';
import SearchBar from './SearchInputBar';
import Copyfile from './CopyFile';
import { Sidebar } from 'primereact/sidebar';
import ShareIcon from '@material-ui/icons/Share';
import ShareFile from './ShareFile';
import DeleteFile from './DeleteFile';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../Css/table.css';
import { ReactComponent as loadingSVG } from '../Assets/loading.svg';
import { saveAs } from 'file-saver';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import streamSaver from 'streamsaver';
// import { Checkbox } from '@material-ui/core';
import { Checkbox } from 'primereact/checkbox';
import util from '../../Assets/util';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';

const tableIcons = {
    Add: AddBox,
    Check: Check,
    Clear: Clear,
    Delete: DeleteOutline,
    DetailPanel: ChevronRight,
    Edit: Edit,
    Export: SaveAlt,
    Filter: FilterList,
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
    ResetSearch: Clear,
    Search: Search,
    SortArrow: ArrowUpward,
    ThirdStateCheck: Remove,
    ViewColumn: ViewColumn,
};

function Table(props) {
    const [data, setData] = useState(props.tableData);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchingValue, setSearchingValue] = useState("");
    const [columns, setColumns] = useState(setCheckboxColumn(props.columns));
    const [folderName, setFolderName] = useState(props.name);
    const [dataLoading, setDataLoading] = useState(props.tableLoading);
    const location = useLocation();
    const paramConst = queryString.parse(location.search)
    const [searchVal, setSearchVal] = useState(paramConst.search);
    const [options, setOptions] = useState({
        actionsColumnIndex: -1,
        sorting: true,
        defaultSort: 'desc',
        groupSort: 'desc',
        pageSize: 20,
        searchText: searchVal,
        pageSizeOptions: [10, 20, 50, 100],
        emptyRowsWhenPaging: false,
        search: false,
        selection: false
    });
    const [copyData, setCopyData] = useState();
    const [shareData, setShareData] = useState();
    const [deleteData, setDeleteData] = useState();
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [selectedFile, setSelectedFile] = useState();
    const [showBackDrop, setShowBackDrop] = useState(false);

    const tableRef = React.createRef();
    useEffect(() => {
        initializeData();
        return () => {
        };
    }, [props.tableData])
    useEffect(() => {
        setDataLoading(props.tableLoading);
        return () => {
        };
    }, [props.tableLoading])
    useEffect(() => {
        if (props.searchVal && props.orgBucketName !== "") {
            searchFileService(props.searchVal, appConstants)
            setSearchVal(props.searchVal)
        } else if (props.searchVal === '') {
            setSearchVal('');
        }
    }, [props.searchVal, props.orgBucketName])
    util.selectedRows$.subscribe(val => {
        setSelectedRows(val);
    });

    useEffect(() => {
        setColumns(setCheckboxColumn(props.columns));
    }, [selectAll, selectedRows]);

    function setCheckboxColumn(columns) {
        let checkboxColumn = {
            title: <Checkbox onChange={handleSelectAll} checked={selectAll} />,
            field: '',
            editable: 'never',
            width: '3%',
            cellStyle: { width: '3%' },
            headerStyle: {},
            render: rowData => (
                <>
                    {(rowData.is_folder || rowData.StorageClass === 'Glacier' || rowData.StorageClass === 'Deep_Archive') ? null : (
                      <Checkbox 
                          onChange={e => handleCheckbox(e.target.checked, rowData)}
                          checked={selectedRows.some(item => item.tableData.id === rowData.tableData.id)}>
                      </Checkbox>
                    )}
                </>
            )
        };
        let newColumns = [...columns];
        newColumns.splice(0, 0, checkboxColumn);
        return newColumns;
    }

    function handleCheckbox(checked, rowData) {
        setSelectedRows(prevSelectedRows => {
            let newSelectedRows;
            if (checked) {
                newSelectedRows = [...prevSelectedRows, rowData];
            } else {
                newSelectedRows = prevSelectedRows.filter(row => row.tableData.id !== rowData.tableData.id);
            }
            setSelectAll(newSelectedRows.length === data.length);
            return newSelectedRows;
        });
    }

    function handleSelectAll(event) {
        const checked = event.target.checked;
        if (checked) {
            const allRows = props.tableData.filter(row => !row.is_folder && row.StorageClass !== 'Glacier');
            setSelectedRows(allRows);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(checked);
    }

    function clearSelectionofRows() {
        props.api.selectedRows = [];
        setSelectedRows([]);
    }
    
    function initializeData() { // props.tableData.sort((a, b) => (a.LastModified > b.LastModified) ? 1 : -1);
        setData(props.tableData);
        setColumns(setCheckboxColumn(props.columns));
        setSelectAll(false);
        setSelectedRows([]);
        if (!paramConst.search && tableRef.current !== null) {
            if (tableRef.current.state.searchText !== "") {
                setSearchVal('');
                tableRef.current.onSearchChange('')
            }
        }
    };
    function initializeDataAfterSearch() {
        setData(props.tableData);
        setSelectedRows([]);
        setColumns(setCheckboxColumn(props.columns));
        if (tableRef.current !== null) {
            if (tableRef.current.state.searchText !== "") {
                setSearchVal('');
                tableRef.current.onSearchChange('')
            }
        }
    };
    async function recursive_getFolder(rowdata, data, baselevel, childLevel) {
        if (!props.api.folderCopyStop) {
            return props.api.getFolderNew(rowdata.Key, props.orgBucketName, '', false).then(
                async (res) => {
                    let _data = res.objects;
                    let files = [];
                    let folders = [];
                    _data.map(x => {
                        x['folderCopy'] = true;
                        x['folderName'] = rowdata.folderName + rowdata.Name + '/';
                        if (!x.is_folder)
                            files.push(x);
                        else
                            folders.push(x);
                    });
                    props.api.folderCopyData.push(...files);
                    if (props.api.folderCopyData.length > 100) {
                        props.api.folderCopyStop = true;
                        return false;
                    } else if (folders.length > 0) {
                        let folderData = [];
                        for (var i = 0; i < folders.length; i++) {
                            folderData[i] = await recursive_getFolder(folders[i], folders, i, childLevel++);
                            if (folderData[i] === false) {
                                return false
                            }
                        }
                        return true;
                    } else {
                        return true;
                    }

                });
        } else {
            return false;
        }
    }
    async function getFolders(rowdata) {
        let data;
        props.api.getFolderNew(rowdata.Key, props.orgBucketName, '', false).then(
            async (res) => {
                let _data = res.objects;
                let files = [];
                let folders = [];
                _data.map(x => {
                    x['folderCopy'] = true;
                    x['folderName'] = rowdata.Name + '/';
                    if (!x.is_folder)
                        files.push(x);
                    else {
                        folders.push(x);
                    }
                });
                props.api.folderCopyData.push(...files);
                if (props.api.folderCopyData.length > 100) {
                    setShowBackDrop(false)
                    props.api.folderCopyStop = true;
                    props.sendTostMessage(`Too many files to copy, please navigate in to folder and do bulk copy.`, 'error');
                } else if (folders.length > 0) {
                    let folderData = [];
                    for (var i = 0; i < folders.length; i++) {
                        folderData[i] = await recursive_getFolder(folders[i], folders, i, 0)
                        if (folderData[i] === false) {
                            props.api.folderCopyStop = true;
                            props.sendTostMessage(`Too many files to copy, please navigate in to folder and do bulk copy.`, 'error');
                            break;
                        }
                    }
                    setShowBackDrop(false)
                    if (!props.api.folderCopyStop) {
                        data = props.api.folderCopyData;
                        setCopyData(data);
                    }
                } else {
                    setShowBackDrop(false)
                    data = props.api.folderCopyData;
                    setCopyData(data);
                }

            });
    }
    function copyFile(event, rowdata) {
        let data;
        if (rowdata.is_folder) {
            setShowBackDrop(true);
            props.api.folderCopyData = [];
            props.api.folderCopyStop = false;
            getFolders(rowdata);
            // setCopyData(data);
        } else {
            data = [rowdata];
            setCopyData(data);
        }

    }
    function shareFile(event, rowdata) {
        setShareData(rowdata);
    }
    function deleteFile(event, rowdata) {
        let data;
        data = [rowdata];
        setDeleteData(data);
    }
    function deleteSuccessful() {
        setDeleteData();
        props.refreshData();
    }
    function shareSuccessful() {
        setShareData();
    }
    function copySuccessful() {
        setCopyData();
    }
    function handleSearchEnter(e) {
        if (e.value || e.value === '') {
            setSearchVal(e.value);
            searchFileService(e.value, appConstants)
        }
    }
    function handleFldrCreate(val) {
        let bucketname = props.orgBucketName;
        const paramConst = queryString.parse(location.search);
        let folder = paramConst.folder ? paramConst.folder + '/' + val : val;
        props.api.createNewFolder(bucketname, folder, props.api.region).then(
            props.sendTostMessage(`Folder Created Succesfully`, 'success')
        );
        props.refreshData();
    }

    function searchFileService(val, appConstants) {
        try {
            var searchValue = val;
            const paramConst = queryString.parse(location.search);
            if (searchValue.length > 3) {
                setDataLoading(true);
                let bucketname = props.orgBucketName;
                let folder = paramConst.folder ? paramConst.folder : " ";
                console.log('Search Text' + searchingValue + ',' + bucketname + ',' + folder + ',');
                props.api.searchFile(bucketname, folder, searchValue).then(
                    resp => {
                        setData(resp);
                        setSelectedRows([]);
                        setColumns(setCheckboxColumn(appConstants.searchResultsFolders));
                        setDataLoading(false);
                    });
            } else if (searchValue.length === 0) {

                initializeDataAfterSearch();
            } else {
                /* if (tableRef.current !== null) {
                    tableRef.current.onSearchChange(searchValue)
                } */
                props.sendTostMessage(`Please enter Min 4 characters to search`, 'error');
            }

        }
        catch (error) {
            console.log(error);
        }
    }
    function downloadProgressBarWithChunks(uploadedParts, totalParts) {
        var percentCompleted = ((uploadedParts / totalParts) * 100).toFixed(2);
        setPercentCompleted(percentCompleted);
    }
    async function downloadSingleFile(row, retries = 3) {
        try {
            const fileName = row.Name.split('.');
            const fileType = fileName[fileName.length - 1].toLowerCase();
            const acceptedFileTypes = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'mov', 'mp4', 'wav', 'zip'];
    
            const downloadURL = await props.api.getDownloadURL(
                props.orgBucketName,
                encodeURIComponent(row.Key),
                props.api.region
            );
    
            console.log(`Attempting to download: ${row.Name} from ${downloadURL}`);
    
            if (acceptedFileTypes.includes(fileType)) {
                const res1 = await props.api.getFileBinaryData(downloadURL, false);
                saveAs(res1.data, row.Name);
            } else {
                saveAs(downloadURL, row.Name);
            }
        } catch (error) {
            console.error(`Error downloading ${row.Name}:`, error);
    
            if (error.response && error.response.status === 403 && retries > 0) {
                console.warn(`403 error encountered. Retrying download for ${row.Name}...`);
                await new Promise(resolve => setTimeout(resolve, 1000));
                return downloadSingleFile(row, retries - 1);
            } else if (retries === 0) {
                console.error(`Failed to download ${row.Name} after retries.`);
            }
        }
    }
    
    function downloadfile(event, rowData) {
        if (rowData.StorageClass === 'Glacier'|| rowData.StorageClass === 'Deep_Archive') {
            props.api.glacierFileInfo = { key: rowData.Key, name: rowData.Name };
            props.handleClickOpen();
            return;
        }
    
        setShowBackDrop(true);
    
        downloadSingleFile(rowData).then(() => {
            setShowBackDrop(false);
        }).catch(error => {
            console.error('Error downloading file:', error);
            setShowBackDrop(false);
        });
    }
    
    async function handleBulkDownload(data) {
        if (data && data.length > 0) {
            setShowBackDrop(true);
            const downloadSet = new Set();
    
            const downloadPromises = data.map(async (row, index) => {
                if (!downloadSet.has(row.Key)) {
                    downloadSet.add(row.Key);
                    await new Promise(resolve => setTimeout(resolve, index * 300)); // Stagger downloads
                    
                    // Try downloading with retries
                    return downloadSingleFile(row, 3); // Set initial retries to 3
                } else {
                    console.warn(`Duplicate entry for ${row.Key} found. Skipping.`);
                }
            });
    
            try {
                await Promise.all(downloadPromises);
            } catch (error) {
                console.error('Error during bulk download:', error);
            } finally {
                setShowBackDrop(false);
            }
        }
    }
    
    
    
    function handleBulkCopy(data) {
        setCopyData(data);
    }
    
    function handleBulkDelete(data) {
        setDeleteData(data);
    }
    
    
    
    function handleUpload() {
        props.handleUpload();
    }
    return (<>
        <Backdrop className="backDrop" open={showBackDrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
        {percentCompleted > 0 ? <>
            <div className="upload">
                <span className="upload-name">{selectedFile} Downloading...</span>
                <ProgressBar variant="success" now={percentCompleted} label={`${percentCompleted}%`} />

            </div>
        </>
            : ''}
        <SearchBar handleSearchEnter={handleSearchEnter} handleFldrCreate={handleFldrCreate} searchVal={searchVal} selectedRows={selectedRows}
            handleBulkCopy={handleBulkCopy} handleBulkDownload={handleBulkDownload} handleUpload={handleUpload}
            uploadAccess={props.uploadAccess} deleteAccess={props.deleteAccess} handleBulkDelete={handleBulkDelete} bucket_name={props.bucket.name} user={props.api.user.email}/>
        <MaterialTable
            tableRef={tableRef}
            options={options}
            icons={
                tableIcons
            }

            title={props.title}
            columns={
                columns
            }
            data={
                data
            }
            /* onClick={
                //  this.handleCellClick
            } */
            onRowClick={
                (event, rowData) => props.handleRowClicked(event, rowData)
            }
            onRowsPerPageChange={(event, page) => {
                props.rowsChange(event, page)
            }}
            onPageChange={(event, page) => {
                clearSelectionofRows();
                props.paginationChange(event + 1, page)
            }}
            /* onSearchChange={
                (e) => {
                    setSearchingValue(e);
                    searchFileService(e, appConstants)
                }
            } */
            actions={[
                rowData => ({
                    icon: !rowData.downloading ? CloudDownloadIcon : loadingSVG,
                    tooltip: 'Download File',
                    onClick: (event, rowData) => downloadfile(event, rowData),
                    disabled: rowData.is_folder === true,
                    hidden: rowData.is_folder,
                    isFreeAction: true,
                    //position: 'row'

                }),
                rowData => ({
                    icon: FileCopyIcon,
                    tooltip: 'Copy File',
                    onClick: (event, rowData) => copyFile(event, rowData),
                    // disabled: rowData.is_folder === true,
                    disabled: false,
                    hidden: ["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket.name)  || (!props.api.folderAccess && ["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket.name)),
                    //isFreeAction: false,
                    position: 'row'
                }),
                rowData => ({
                    icon: ShareIcon,
                    tooltip: 'Share File',
                    onClick: (event, rowData) => shareFile(event, rowData),
                    disabled: rowData.is_folder === true,
                    hidden: ["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket.name) || rowData.is_folder || (!props.api.folderAccess && ["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket.name)),
                    //isFreeAction: false,
                    position: 'row'
                }),
                rowData => ({
                    icon: DeleteIcon,
                    tooltip: 'Delete File',
                    onClick: (event, rowData) => deleteFile(event, rowData),
                    disabled: ["sactestbucket", "sacworkforceplanning","sacworkforceplanning-prd"].includes(props.bucket.name) && rowData.is_folder === true,
                    hidden: !props.api.folderAccess || !props.api.folderDelete,
                    //isFreeAction: false,
                    position: 'row'
                }),
            ]}
            isLoading={dataLoading}
            editable={{
                isEditable: rowData => props.api.folderAccess, // only name(a) rows would be editable
                isDeletable: rowData => false, // only name(a) rows would be deletable
                onRowUpdate: (newData, oldData) => {
                    return new Promise(resolve => {
                        setTimeout(() => {
                            let bucketname = props.orgBucketName;
                            let ppathname = oldData.Key;
                            if (oldData.Name.trim() !== newData.Name.trim()) {
                                props.api.renameFileName(bucketname, ppathname, newData.Name.trim()).then(
                                    resp => {
                                        props.sendTostMessage(`File rename success`, 'success');
                                        props.refreshData();
                                    }).catch(error => {
                                        props.sendTostMessage(`File rename Failed`, 'error');
                                    });
                                resolve();
                            } else {
                                // props.refreshData();
                                props.sendTostMessage(`File can not be renamed with same name`, 'error');
                                resolve();
                            }
                        }, 600);
                    })
                },
                // onRowDelete: oldData =>
                //   new Promise(resolve => {
                //     setTimeout(() => {
                //       resolve();
                //       const data = [...this.state.data];
                //       data.splice(data.indexOf(oldData), 1);
                //       this.setState({
                //         ...this.state,
                //         data
                //       });
                //     }, 600);
                //   }), 
            }}
        />
        <Sidebar visible={copyData ? true : false} position="right" onHide={(e) => { setCopyData() }}
            className="sidebarMain">
            {copyData ? <Copyfile {...props} copyData={copyData} copySuccessful={copySuccessful} /> : ''}
        </Sidebar>
        <Sidebar visible={shareData ? true : false} position="right" onHide={(e) => { setShareData() }}
            className="sidebarShare">
            {shareData ? <ShareFile {...props} shareData={shareData} shareSuccessful={shareSuccessful} /> : ''}
        </Sidebar>
        <Sidebar visible={deleteData ? true : false} position="right" onHide={(e) => { deleteSuccessful() }}
            className="sidebarShare">
            {deleteData ? <DeleteFile {...props} deleteData={deleteData} deleteSuccessful={deleteSuccessful} /> : ''}
        </Sidebar>
    </>);
}

export default withAuth(Table);