import React, { useState, useEffect } from 'react';
// SKIP_PREFLIGHT_CHECK=true
import { withStyles } from '@material-ui/core/styles';
import { withAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DragDrop from '../../Common/Component/Js/DragAndDrop';
import './dropzone.css';
/* import {
  BarLoader
} from 'react-spinners'; */

import Table from '../../Common/Component/Js/Table';
import appConstants from '../../Common/Assets/appConstants';
import queryString from 'query-string';
import $ from 'jquery';
import FileViewer from '../../Common/Component/Js/FilePreview/components/file-viewer';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import util from '../../Common/Assets/util';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 30
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  icon: {
    margin: theme.spacing(2),
  }
});

function S3File(props) {
  const location = useLocation();
  const paramConst = queryString.parse(location.search)
  const [data, setData] = useState([]);
  const [bucket, setBucket] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [paramValues, setparamValues] = useState(paramConst);
  const [folderName, setFolderName] = useState(() => {
    if (!paramConst.folder)
      return ' ';
    else
      return paramConst.folder;
  });
  const [orgBucketName, setOrgBucketName] = useState('');
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState();
  const [bucketid, setBucketid] = useState(paramConst.bucketid);
  const [columns, setColumns] = useState(appConstants.folderColumns);
  const [api, setApi] = useState(props.api);
  const [searchVal, setSearchVal] = useState();
  const [modelOpen, setModelOpen] = useState(false);
  const [preview, setPreview] = useState({});
  const [uploadView, setUploadView] = useState(false);
  api.pageSize = api.pageSize > 10 ? api.pageSize : 10;
  api.presentPage = 1;
  api.prevToken = { 0: '' };
  //api.glacierFileInfo = {};


  async function initializeData() {
    try {
      api.nextToken = '';
      let paramValues = queryString.parse(location.search);
      let folders;
      if (paramValues.FA === 0 || paramValues.FA === '0') api.fullaccess = false;
      if (paramValues.DA === false || paramValues.DA === 'false') api.folderAccess = false;
      (folderName && folderName === ' ') ? setTitle(paramValues.bucket) :
        setTitle(folderName.split('/')[folderName.split('/').length - 1]);
      const bucket = await api.getBucketWithId(bucketid);
      var bucketData = await bucket;
      setBucket(bucketData);
      api.folderDelete = bucketData.enable_delete;
      if (api.fullaccess || paramValues.folder) {
        setColumns(appConstants.folderColumns);
        folders = await api.getFolderNew(folderName, bucketData.name, '');
      }
      else {
        setColumns(appConstants.restrictedAccessFolders);
        folders = await api.getBucket(bucketid);
      }
      const folderData = await folders;
      api.region = folderData.region
      if (!(props.api.fullaccess || paramValues.folder)) {
        folderData.map((x) => {
          x.is_folder = true;
          x['Name'] = x.name;
        });
        setData(folderData);
      } else {
        setData(folderData.objects);
        api.prevToken = { 0: '' };
        api.nextToken = encodeURIComponent(folderData.next_token);
      }
      if (bucket)
        setOrgBucketName(bucket.name);
      if (paramValues.search) setSearchVal(paramValues.search);
      // Modify Bucket Name to its alias, if it exists.
      if (bucketData.alias)
        bucketData.name = bucketData.alias
      setTableLoading(false);
      clearSelectionofRows();
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    initializeData();
    return () => {
    };
  }, [folderName])

  useEffect(() => {
    setTableLoading(true);
    let paramValues = queryString.parse(location.search);
    setparamValues(paramValues);
    setFolderName(() => {
      if (!paramValues.folder)
        return ' ';
      else
        return paramValues.folder;
    });
    setBucketid(paramValues.bucketid);
    return () => {
    };
  }, [useLocation().search])

  function clearSelectionofRows() {
    props.api.selectedRows = [];
    util.setSelectedRows([]);
    // setSelectedRows([]);
  }
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose(event) {
    if (event.target.innerText === 'YES') {
      downloadGlacierFile()
    }
    setOpen(false);
  }

  function handleRowClicked(event, rowData) {
    event.preventDefault();
    // if (event.target.type === "checkbox") {
    if (/checkbox/.test(event.target.outerHTML)) {
      return;
    } else if (rowData['bucket']) {
      var folder = rowData.Name.replace(/\//g, '%2F');
      api.folderAccess = rowData.user_permission === 'FullAccess' ? true : false;
      api.folderDelete = rowData.enable_delete;
      props.history.push(`${props.location.pathname}?bucketid=${bucket.id}&bucket=${bucket.name}&folder=${folder}&FA=0${paramValues.DA ? `&DA=${api.folderAccess}` : ``}`)
    } else {
      let folArray = rowData.Key.split('/').filter((x) => x !== '');

      var folder1 = folArray.join('/').replace(/\//g, '%2F').replace(/&/g, '%26').replace(/\+/g, '%2B')
      if (rowData.is_folder) {
        api.nextToken = '';
        setSearchVal('');
        props.history.push(`${props.location.pathname}?bucketid=${bucket.id}&bucket=${bucket.name}` +
          `&folder=${folder1}&FA=${paramValues.FA ? paramValues.FA : 1}${paramValues.DA ? `&DA=${paramValues.DA}` : ``}`)
      } else if (rowData.StorageClass === 'Glacier' || rowData.StorageClass === 'Deep_Archive') {
        api.glacierFileInfo = { 'key': rowData.Key, 'name': rowData.Name };
        handleClickOpen();
      } else {
        api.getDownloadURL(orgBucketName, rowData.Key.replace(/\//g, '%2F').replace(/\+/g, '%2B').replace(/&/g, '%26'),props.api.region).then((res) => {
          let fileName = rowData.Name.split('.');
          let fileType = fileName[fileName.length - 1];
          let acceptedFileTypes = ['csv', 'jpg', 'jpeg', 'gif', 'bmp', 'png', 'PNG', 'pdf', 'mp3', 'mp4', 'webm', 'mov', 'wav', 'xlsx', 'xls', 'docx', 'doc'];  //,'avi', 'wmv' 
          if (acceptedFileTypes.indexOf(fileType.toLowerCase()) > -1) {
            setPreview({ type: fileType, path: res });
            setModelOpen(true);
          } else {
            window.open(res);
          }
        });
      }
    }
  }

  function rowsChange(event, page) {
    api.pageSize = event;
    getDataOnPaginate(1, null)
  }
  async function getDataOnPaginate(event, page) {
    //setPresentPage(event);
    api.presentPage = event;
    let totalData = data.length;
    let presentCount = event * api.pageSize;
    let flag = (totalData - presentCount) <= api.pageSize ? true : false;
    if (api.nextToken !== "" && flag) {
      let folders;
      let paramValues = queryString.parse(location.search);
      if (props.api.fullaccess || paramValues.folder) {
        setColumns(appConstants.folderColumns);
        api.prevToken[Math.floor((api.pageSize * api.presentPage) / 301) + 1] = api.nextToken;
        folders = await api.getFolderNew(folderName, orgBucketName, api.nextToken);
        const folderData = await folders;
        let newdata = [...data, ...folderData.objects]
        setData(newdata);
        api.nextToken = encodeURIComponent(folderData.next_token);
      }
    }
  }
  function sendTostMessage(msg, success) {
    if (success === "success") {
      props.toast.success(msg, {
        position: props.toast.POSITION.TOP_CENTER
      });
    } else {
      props.toast.error(msg, {
        position: props.toast.POSITION.TOP_CENTER
      });
    }
  }

  function refreshData(acceptedFiles, success, buckName) {
    setTableLoading(true);
    $(window).unbind('beforeunload');
    let pageIndex = Math.floor((api.pageSize * api.presentPage) / 300);
    let token = api.prevToken[pageIndex];
    const response = api.getFolderNew(folderName, orgBucketName, token);
    response.then((resp) => {
      (acceptedFiles) && sendTostMessage(`${acceptedFiles}`, 'success');
      let dataArray = [...data];
      dataArray.splice(pageIndex * 300, 300);
      dataArray.splice(pageIndex * 300, 0, ...resp.objects);
      setData(dataArray);
      setTableLoading(false);
    })
  }

  function refreshDataCallback(error) {
    $(window).unbind('beforeunload');
    props.toast.error(error.message, {
      position: props.toast.POSITION.TOP_CENTER
    });
  }

  function downloadGlacierFile() {
    new Promise(resolve => {
      // setTimeout(() => {
      let bucketname = orgBucketName;
      let fileKey = api.glacierFileInfo.key;
      let fileName = api.glacierFileInfo.name;
      api.restoreGlacierFile(bucketname, fileKey, fileName, props.api.region).then(
        resp => {
          if (resp.restore_status === 'submitted') {
            props.toast.success(`Restore process request has been submitted.You will get an email notification,once file is available to download`, {
              position: props.toast.POSITION.TOP_CENTER
            });
          } else if (resp.restore_status === 'completed') {
            window.open(resp.downloadUrl)
          } else {
            props.toast.success(`Restore is in process.You will get an email notification,once file is available to download`, {
              position: props.toast.POSITION.TOP_CENTER
            });
          }
        });
      resolve();
      // }, 600);
    })
  }
  function handleModelClose() {
    setModelOpen(false);
    setUploadView(false)
    setPreview({});
  }
  function handleUpload() {
    setUploadView(true);
    setModelOpen(true);
  }
  function handleUploadClose() {
    refreshData(null, null, null);
    $(window).unbind('beforeunload')
    setModelOpen(false);
    setUploadView(false)
    setPreview({});
  }

  return (
    <div className={styles.root}>

      {modelOpen ? <Modal
        open={modelOpen}
        onClose={handleModelClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick={uploadView}
        disableEscapeKeyDown={uploadView}
      >
        {modelOpen && preview.type ? <div className="modelMain">
          <FileViewer
            fileType={preview.type}
            filePath={preview.path}
          />
        </div> : (modelOpen && uploadView && orgBucketName !== '') ? <div className="modelMain">
          <div><CloseIcon className="dragdrop-close" onClick={handleUploadClose} fontSize="small" /></div>
          <DragDrop sendTostMessage={sendTostMessage}
            folderName={folderName} bucket={orgBucketName} bucketid={bucketid} {...props}
            refreshData={refreshData} refreshDataCallback={refreshDataCallback} />
          {/* <div className="dragdrop-end">.</div> */}
        </div> : ''}

      </Modal> : ''}
      {/* {modelOpen ? <Modal
        open={modelOpen}
        onClose={handleModelClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modelOpen && preview.type ? <div className="modelMain">
          <FileViewer
            fileType={preview.type}
            filePath={preview.path}
          />
        </div> : ''}

      </Modal> : ''} */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This file is stored in glacier.  Are you sure you want to retrieve it and download?  Click ‘YES’ to continue.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Yes
            </Button>
          <Button onClick={handleClose} color="primary">
            No
            </Button>
        </DialogActions>
      </Dialog>

      {/* {(((api.fullaccess || paramValues.folder) && api.folderAccess) && orgBucketName !== '') ?
        <DragDrop sendTostMessage={sendTostMessage} folderName={folderName} bucket={orgBucketName}
          bucketid={bucketid} {...props} sendTostMessage={sendTostMessage}
          refreshData={refreshData} refreshDataCallback={refreshDataCallback} />
        : <div style={{ 'marginBlockEnd': '0.5em' }} />} */}
      <div style={{ 'marginBlockEnd': '0.5em' }} />
      <Table tableLoading={tableLoading} orgBucketName={orgBucketName} paginationChange={getDataOnPaginate}
        handleRowClicked={handleRowClicked} rowsChange={rowsChange}
        title={title} {...props} tableData={data} columns={columns} name={folderName} bucketid={bucketid}
        bucket={bucket} refreshData={refreshData} sendTostMessage={sendTostMessage} searchVal={searchVal}
        handleClickOpen={handleClickOpen} handleUpload={handleUpload} deleteAccess={api.folderDelete} uploadAccess={((api.fullaccess || paramValues.folder) && api.folderAccess)} />
    </div>
  );
}

export default withStyles(styles)(withAuth(S3File));
